<template>
  <!-- table -->
  <!-- margin-top: 4.3vw; -->
  <div
    style="
      flex: 0.8;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 3.5vw;
    "
  >
    <div
      v-show="
        view_radar_state
          ? view_cctv_state
            ? view_sound_state
              ? true
              : false
            : false
          : false
      "
      style="
        width: 86%;
        height: 66%;
        background: linear-gradient(to right, #b51be4, #fa71a3);

        border-radius: 40px;
        display: inline-block;
        box-shadow: 0px 3px 6px #00000052;
        margin-top: 30px;
        z-index: 1;
      "
    >
      <div
        style="
          margin: 3px;
          padding: 24px;
          display: flex;
          align-items: center;
          height: 5.4vh;
          background: #232323;
          border-radius: 40px;
          box-shadow: 0px 3px 6px #00000052;
        "
      >
        <div
          style="float: left; width: 5vh; height: 5.2vh; margin-left: -0.3vw"
        >
          <div style="margin: 0.52vh; width: 3vh; height: 3vh">
            <div
              v-on:mouseover="showTooltip('cctv')"
              v-on:mouseout="hideTooltip('cctv')"
            >
              <img
                src="resources/img/icons/dashboard/effect/Camera_1.svg"
                style="float: left; width: 4vh; height: 4vh; margin-left: -10px"
              />
            </div>
            <div
              class="cctv-tooltip"
              v-bind:class="[isCctvTooltip ? 'show-tooltip' : 'hide-tooltip']"
            >
              <div style="color: white">
                <h3 style="color: rgb(255, 142, 67)">
                  {{ $t("label.L0100") }} {{ cctv_total }}
                  {{ $t("label.L0040") }}
                </h3>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0022") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_pedestrian_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0023") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_reverse_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0024") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_fire_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0025") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_sudden_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0026") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_delay_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0027") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-for="item in 17">
          <div
            style="width: 1vh; height: 1vh; display: flex; align-items: center"
          >
            <div
              class="circle-animation"
              v-bind:style="{ 'animation-delay': 0.1 * item + 's' }"
              style="
                background-color: rgba(199, 45, 255, 1);
                width: 0.2vh;
                height: 0.2vh;
                border-radius: 20px;
                margin: auto;
              "
            ></div>
          </div>
        </template>

        <div style="width: 8vh; height: 6.6vh">
          <div style="margin-left: 37%; margin: 0.52vh">
            <div
              v-on:mouseover="showTooltip('radar')"
              v-on:mouseout="hideTooltip('radar')"
              style="float: left; margin: 0.3vh"
            >
              <img
                src="resources/img/icons/dashboard/effect/Camera_2.svg"
                style="float: left; width: 4vh; height: 4vh; margin: 0.5vh"
              />
            </div>
            <div
              class="radar-tooltip"
              v-bind:class="[isRadarTooltip ? 'show-tooltip' : 'hide-tooltip']"
            >
              <div style="color: white">
                <h3 style="color: rgb(255, 142, 67)">
                  {{ $t("label.L0100") }} {{ radar_total }}
                  {{ $t("label.L0040") }}
                </h3>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0022") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_pedestrian_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0023") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_reverse_count }}
                  </label>

                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0024") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_fire_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0025") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_sudden_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0026") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_delay_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0027") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-for="item in 17">
          <div
            style="width: 1vh; height: 1vh; display: flex; align-items: center"
          >
            <div
              class="circle-reverse-animation"
              v-bind:style="{ 'animation-delay': 1.7 - 0.1 * item + 's' }"
              style="
                background-color: rgba(250, 113, 163, 1);
                width: 0.2vh;
                height: 0.2vh;
                border-radius: 20px;
                margin: auto;
              "
            ></div>
          </div>
        </template>
        <div style="width: 4vh; height: 6.6vh; border-radius: 20px">
          <div
            style="margin: 0.52vh; width: 3vh; height: 3vh; border-radius: 20px"
          >
            <div
              v-on:mouseover="showTooltip('sound')"
              v-on:mouseout="hideTooltip('sound')"
              style="
                float: left;
                margin: 0.3vh;
                width: 2.5vh;
                height: 2.5vh;
                border-radius: 20px;
              "
            >
              <img
                src="resources/img/icons/dashboard/effect/Microphone_1.svg"
                style="float: left; width: 4vh; height: 4vh; margin: 0.5vh"
              />
            </div>
            <div
              class="sound-tooltip"
              v-bind:class="[isSoundTooltip ? 'show-tooltip' : 'hide-tooltip']"
            >
              <div style="color: white">
                <h3 style="color: rgb(255, 142, 67)">
                  {{ $t("label.L0028") }}< {{ sound_total }}
                  {{ $t("label.L0040") }}
                </h3>
                <div>
                  <label class="label-custom-effect" for=""
                    >{{ $t("label.L0091") }}<</label
                  >
                  <label for="" class="label-custom-count">
                    {{ accident_sound }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for=""
                    >{{ $t("label.L0092") }}<</label
                  >
                  <label for="" class="label-custom-count"
                    >{{ horn_sound }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label
                    class="label-custom-effect"
                    style="margin-right: 6px !important"
                    for=""
                    >{{ $t("label.L0093") }}<</label
                  >
                  <label for="" class="label-custom-count">
                    {{ sudden_sound }}
                  </label>

                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for=""
                    >{{ $t("label.L0094") }}<</label
                  >
                  <label for="" class="label-custom-count">
                    {{ noise }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="
        view_radar_state
          ? view_cctv_state
            ? view_sound_state
              ? false
              : true
            : false
          : false
      "
      style="
        width: 86%;
        height: 66%;
        background: linear-gradient(to right, #b51be4, #fa71a3);

        border-radius: 40px;
        display: inline-block;
        box-shadow: 0px 3px 6px #00000052;
        margin-top: 30px;
        z-index: 1;
      "
    >
      <div
        style="
          margin: 3px;
          padding: 24px;
          display: flex;
          align-items: center;
          height: 5.4vh;
          background: #232323;
          border-radius: 40px;
          box-shadow: 0px 3px 6px #00000052;
        "
      >
        <div
          style="float: left; width: 5vh; height: 5.2vh; margin-left: -0.3vw"
        >
          <div style="margin: 0.52vh; width: 3vh; height: 3vh">
            <div
              v-on:mouseover="showTooltip('cctv')"
              v-on:mouseout="hideTooltip('cctv')"
            >
              <img
                src="resources/img/icons/dashboard/effect/Camera_1.svg"
                style="float: left; width: 4vh; height: 4vh; margin-left: -10px"
              />
            </div>
            <div
              class="cctv-tooltip"
              v-bind:class="[isCctvTooltip ? 'show-tooltip' : 'hide-tooltip']"
            >
              <div style="color: white">
                <h3 style="color: rgb(255, 142, 67)">
                  {{ $t("label.L0100") }} {{ cctv_total }}
                  {{ $t("label.L0040") }}
                </h3>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0022") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_pedestrian_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0023") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_reverse_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0024") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_fire_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0025") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_sudden_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0026") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_delay_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0027") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ cctv_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-for="item in 34">
          <div
            style="width: 1vh; height: 1vh; display: flex; align-items: center"
          >
            <div
              class="circle-reverse-animation"
              v-bind:style="{ 'animation-delay': 1.7 - 0.1 * item + 's' }"
              style="
                background-color: rgba(250, 113, 163, 1);
                width: 0.2vh;
                height: 0.2vh;
                border-radius: 20px;
                margin: auto;
              "
            ></div>
          </div>
        </template>
        <div style="width: 4vh; height: 6.6vh; border-radius: 20px">
          <div
            style="margin: 0.52vh; width: 3vh; height: 3vh; border-radius: 20px"
          >
            <div
              v-on:mouseover="showTooltip('radar')"
              v-on:mouseout="hideTooltip('radar')"
              style="
                float: left;
                margin: 0.3vh;
                width: 2.5vh;
                height: 2.5vh;
                border-radius: 20px;
              "
            >
              <img
                src="resources/img/icons/dashboard/effect/Camera_2.svg"
                style="float: left; width: 4vh; height: 4vh; margin: 0.5vh"
              />
            </div>
            <div
              class="radar-tooltip"
              v-bind:class="[isRadarTooltip ? 'show-tooltip' : 'hide-tooltip']"
            >
              <div style="color: white">
                <h3 style="color: rgb(255, 142, 67)">
                  {{ $t("label.L0100") }} {{ radar_total }}
                  {{ $t("label.L0040") }}
                </h3>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0022") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_pedestrian_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0023") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_reverse_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0024") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_fire_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0025") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_sudden_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0026") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_delay_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0027") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="
        view_radar_state
          ? view_sound_state
            ? view_cctv_state
              ? false
              : true
            : false
          : false
      "
      style="
        width: 86%;
        height: 66%;
        background: linear-gradient(to right, #b51be4, #fa71a3);

        border-radius: 40px;
        display: inline-block;
        box-shadow: 0px 3px 6px #00000052;
        margin-top: 30px;
        z-index: 1;
      "
    >
      <div
        style="
          margin: 3px;
          padding: 24px;
          display: flex;
          align-items: center;
          height: 5.4vh;
          background: #232323;
          border-radius: 40px;
          box-shadow: 0px 3px 6px #00000052;
        "
      >
        <div
          style="float: left; width: 5vh; height: 5.2vh; margin-left: -0.3vw"
        >
          <div style="margin: 0.52vh; width: 3vh; height: 3vh">
            <div
              v-on:mouseover="showTooltip('radar')"
              v-on:mouseout="hideTooltip('radar')"
            >
              <img
                src="resources/img/icons/dashboard/effect/Camera_2.svg"
                style="float: left; width: 4vh; height: 4vh; margin-left: -10px"
              />
            </div>
            <div
              class="radar-tooltip"
              v-bind:class="[isRadarTooltip ? 'show-tooltip' : 'hide-tooltip']"
            >
              <div style="color: white">
                <h3 style="color: rgb(255, 142, 67)">
                  {{ $t("label.L0100") }} {{ radar_total }}
                  {{ $t("label.L0040") }}
                </h3>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0022") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_pedestrian_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0023") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_reverse_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0024") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_fire_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0025") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_sudden_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0026") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_delay_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for="">
                    {{ $t("label.L0027") }}
                  </label>
                  <label for="" class="label-custom-count">
                    {{ radar_stop_count }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-for="item in 34">
          <div
            style="width: 1vh; height: 1vh; display: flex; align-items: center"
          >
            <div
              class="circle-reverse-animation"
              v-bind:style="{ 'animation-delay': 1.7 - 0.1 * item + 's' }"
              style="
                background-color: rgba(250, 113, 163, 1);
                width: 0.2vh;
                height: 0.2vh;
                border-radius: 20px;
                margin: auto;
              "
            ></div>
          </div>
        </template>
        <div style="width: 4vh; height: 6.6vh; border-radius: 20px">
          <div
            style="margin: 0.52vh; width: 3vh; height: 3vh; border-radius: 20px"
          >
            <div
              v-on:mouseover="showTooltip('sound')"
              v-on:mouseout="hideTooltip('sound')"
              style="
                float: left;
                margin: 0.3vh;
                width: 2.5vh;
                height: 2.5vh;
                border-radius: 20px;
              "
            >
              <img
                src="resources/img/icons/dashboard/effect/Microphone_1.svg"
                style="float: left; width: 4vh; height: 4vh; margin: 0.5vh"
              />
            </div>
            <div
              class="sound-tooltip"
              v-bind:class="[isSoundTooltip ? 'show-tooltip' : 'hide-tooltip']"
            >
              <div style="color: white">
                <h3 style="color: rgb(255, 142, 67)">
                  {{ $t("label.L0028") }}< {{ sound_total }}
                  {{ $t("label.L0040") }}
                </h3>
                <div>
                  <label class="label-custom-effect" for=""
                    >{{ $t("label.L0091") }}<</label
                  >
                  <label for="" class="label-custom-count">
                    {{ accident_sound }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for=""
                    >{{ $t("label.L0092") }}<</label
                  >
                  <label for="" class="label-custom-count"
                    >{{ horn_sound }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label
                    class="label-custom-effect"
                    style="margin-right: 6px !important"
                    for=""
                    >{{ $t("label.L0093") }}<</label
                  >
                  <label for="" class="label-custom-count">
                    {{ sudden_sound }}
                  </label>

                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
                <div>
                  <label class="label-custom-effect" for=""
                    >{{ $t("label.L0094") }}<</label
                  >
                  <label for="" class="label-custom-count">
                    {{ noise }}
                  </label>
                  <label class="label-custom-label" for="">{{
                    $t("label.L0040")
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import EventBus from "@/event-bus";

export default {
  name: "Effect",
  data: function () {
    return {
      message: "test",
      // 레이더 툴팁 //
      radar_total: 0,
      radar_stop_count: 0,
      radar_reverse_count: 0,
      radar_fire_count: 0,
      radar_pedestrian_count: 0,
      radar_delay_count: 0,
      radar_sudden_stop_count: 0,
      isRadarTooltip: false,
      // 사운드 툴팁 //
      sound_total: 0,
      accident_sound: 0,
      horn_sound: 0,
      sudden_sound: 0,
      noise: 0,
      isSoundTooltip: false,
      // 영상 툴팁 //
      cctv_total: 0,
      cctv_stop_count: 0,
      cctv_reverse_count: 0,
      cctv_fire_count: 0,
      cctv_pedestrian_count: 0,
      cctv_delay_count: 0,
      cctv_sudden_stop_count: 0,
      isCctvTooltip: false,
      // 2023/07/04 임시 트루
      view_radar_state: false,
      view_cctv_state: false,
      view_sound_state: false,
    };
  },
  methods: {
    showTooltip: function (type) {
      if (type == "sound") {
        this.isSoundTooltip = true;
      } else if (type == "radar") {
        this.isRadarTooltip = true;
      } else if (type == "cctv") {
        this.isCctvTooltip = true;
      }
    },
    hideTooltip: function (type) {
      if (type == "sound") {
        this.isSoundTooltip = false;
      } else if (type == "radar") {
        this.isRadarTooltip = false;
      } else if (type == "cctv") {
        this.isCctvTooltip = false;
      }
    },
    async initEffect() {
      var me = this;

      const response = await this.api.getDashboardStatusList({
        tunnelCode: "GRR", // 터널 코드 ( 예시 : NS2 )
      });
      const { data } = response.data;
      //console.log("initEffect response");
      var statusList = data.statusList;

      // for (var i = 0; i < statusList.length; i++) {
      //   var equip = statusList[i];

      //   // console.log("equip.tech : ",equip.tech);
      //   // console.log("equip.state : ",equip.state );

      //   if (equip.tech == "R" && me.view_radar_state == false) {
      //     if (equip.state == 0 || equip.state == 1) {
      //       me.view_radar_state = true;
      //     }
      //   } else if (equip.tech == "S" && me.view_sound_state == false) {
      //     if (equip.state == 0 || equip.state == 1) {
      //       me.view_sound_state = true;
      //     }
      //   } else if (equip.tech == "C" && me.view_cctv_state == false) {
      //     if (equip.state == 0 || equip.state == 1) {
      //       me.view_cctv_state = true;
      //     }
      //   }
      // }
      me.view_radar_state = true;
      me.view_cctv_state = true;
      me.view_sound_state = true;
    },
  },
  mounted: function () {
    var me = this;

    EventBus.$on("EffectSoundCount", function (type, count, ChkClear) {
      if (ChkClear) {
        me.sound_total = 0;
        me.accident_sound = 0;
        me.horn_sound = 0;
        me.sudden_sound = 0;
        me.noise = 0;
      } else {
        if (type === 11) me.accident_sound = me.accident_sound + count;
        else if (type === 12) me.horn_sound = me.horn_sound + count;
        else if (type === 13) me.sudden_sound = me.sudden_sound + count;
        else if (type === 14) me.noise = me.noise + count;
        me.sound_total = me.sound_total + count;
      }
    });

    EventBus.$on("EffectRadarCount", function (type, count, ChkClear) {
      if (ChkClear) {
        me.radar_total = 0;
        me.radar_stop_count = 0;
        me.radar_pedestrian_count = 0;
        me.radar_reverse_count = 0;
        me.radar_fire_count = 0;
        me.radar_delay_count = 0;
        me.radar_sudden_stop_count = 0;
      } else {
        if (type === 1) me.radar_stop_count = me.radar_stop_count + count;
        else if (type === 2)
          me.radar_pedestrian_count = me.radar_pedestrian_count + count;
        else if (type === 3)
          me.radar_reverse_count = me.radar_reverse_count + count;
        else if (type === 6) me.radar_fire_count = me.radar_fire_count + count;
        else if (type === 9)
          me.radar_delay_count = me.radar_delay_count + count;
        else if (type === 4)
          me.radar_sudden_stop_count = me.radar_sudden_stop_count + count;
        me.radar_total = me.radar_total + count;
      }
    });

    EventBus.$on("EffectCctvCount", function (type, count, ChkClear) {
      if (ChkClear) {
        me.cctv_total = 0;
        me.cctv_stop_count = 0;
        me.cctv_pedestrian_count = 0;
        me.cctv_reverse_count = 0;
        me.cctv_delay_count = 0;
        me.cctv_fire_count = 0;
        me.cctv_sudden_stop_count = 0;
      } else {
        if (type === 1) me.cctv_stop_count = me.cctv_stop_count + count;
        else if (type === 2)
          me.cctv_pedestrian_count = me.cctv_pedestrian_count + count;
        else if (type === 3)
          me.cctv_reverse_count = me.cctv_reverse_count + count;
        else if (type === 6) me.cctv_fire_count = me.cctv_fire_count + count;
        else if (type === 9) me.cctv_delay_count = me.cctv_delay_count + count;
        else if (type === 4)
          me.cctv_sudden_stop_count = me.cctv_sudden_stop_count + count;
        me.cctv_total = me.cctv_total + count;
      }
    });
  },
  created: function () {
    this.initEffect();
  },
  beforeDestroy: function () {
    //socket.deleteListener(this);
    EventBus.$off("EffectSoundCount");
    EventBus.$off("EffectCctvCount");
    EventBus.$off("EffectRadarCount");
  },
};
</script>
<style>
@keyframes circle-size {
  0% {
    width: 0.5vh;
    height: 0.5vh;
  }
  100% {
    width: 0.2vh;
    height: 0.2vh;
  }
}
.circle-animation {
  animation-duration: 1.5s;
  animation-name: circle-size;
  animation-iteration-count: infinite;
}

@keyframes circle-reverse-size {
  0% {
    width: 0.5vh;
    height: 0.5vh;
  }
  100% {
    width: 0.2vh;
    height: 0.2vh;
  }
}

.circle-reverse-animation {
  animation-duration: 1.5s;
  animation-name: circle-reverse-size;
  animation-iteration-count: infinite;
  /* animation-direction: reverse; */
}

/* theme */
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.radar-tooltip {
  position: absolute;
  margin-left: 40px;
  margin-top: -25px;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #2c2c2c;
}

.cctv-tooltip {
  position: absolute;
  margin-left: 40px;
  margin-top: -25px;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #2c2c2c;
}

.sound-tooltip {
  position: absolute;
  margin-left: 40px;
  margin-top: -25px;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #2c2c2c;
}

.show-tooltip {
  display: block;
}

.hide-tooltip {
  display: none;
}
.label-custom-effect {
  margin-bottom: 0px !important;
  margin-right: 22px;
  color: white;
  font-size: 1.2rem;
}
.label-custom-count {
  position: absolute;
  right: 24%;
  margin-bottom: 0px !important;
  text-align: right !important;
  color: white;
  font-size: 1.2rem;
}
.label-custom-label {
  position: absolute;
  right: 9%;
  margin-bottom: 0px !important;
  color: white;
  font-size: 1.2rem;
}
</style>
